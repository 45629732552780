body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size);
  color: var(--main_color);
  background: var(--bg_light_1);
  // Hide scrollbars
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  ::-webkit-scrollbar {
    display: none;
  }
}

p {
  line-height: 24px;
}

a {
  color: var(--link_color);
  text-decoration: unset;
}

:root {
  --brand-color: #102c2e;
  --main_color: #132f31;
  --second_color: #ffffff;
  --third_color: #001039;
  --color-action: rgb(48 111 116);
  --bg_light_1: #fafafa;
  --bg_light_2: #f6f6f6;
  --bg_light_3: #e4e4e4;
  --bg_light_disabled: #ffc8c8;
  --bg_light_enabled: #6fff91;
  --link_color: #005072;
  --color-black: rgba(0, 0, 0, 0.8);
  --color-gray: rgba(213, 213, 213, 0.8);

  --font-size: 16px;

  --border_radius: 10px;

  --box_shadow: 0 1px 11px 1px rgb(0 0 0 / 20%);

  --scale: 4px;
  --scale_1: calc(var(--scale));
  --scale_2: calc(var(--scale) * 2);
  --scale_3: calc(var(--scale) * 3);
  --scale_4: calc(var(--scale) * 4);
  --scale_5: calc(var(--scale) * 5);
  --scale_6: calc(var(--scale) * 6);

  --button_bg: var(--bg_light_3);
  --button_bg_hover: var(--bg_light_1);
  --button_color: var(--main_color);
}

input,
textarea {
  border: transparent;
  box-shadow: unset;
  color: var(--main_color);
  padding: var(--scale_2);
  outline: unset;
  font-size: var(--font-size);
}

button,
select {
  border: transparent;
  box-shadow: unset;
  background: var(--bg_light_3);
  padding: var(--scale_2) var(--scale_4);
  font-size: var(--font-size);
  border-radius: 12px;
  cursor: pointer;
}

button:disabled {
  background: var(--bg_light_disabled);
}

h1,
h2,
h3 {
}

h1 {
  font-weight: 300;
  font-size: 58px;
  max-width: 700px;
  text-align: left;
}

h2 {
  font-weight: normal;
  font-size: 26px;
  margin: 100px 0 60px 0;
}

.box {
  background: var(--bg_light_2);
  box-shadow: var(--box_shadow);
  padding: var(--scale_4);
  border-radius: var(--border_radius);
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

span.highlight {
  background: rgb(0 198 201 / 44%);
  padding: 2px 8px;
  color: var(--third_color);
  border-radius: 12px;
}

span.highlight.yellow {
  background: rgb(218 232 0 / 47%);
  color: black;
}

span.action {
  color: var(--color-action);
  font-weight: 400;
}

li {
  line-height: 26px;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.mobile-only {
  display: none;
}

.max-width {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.react-tooltip {
  background: white !important;
  color: var(--color-black) !important;
  box-shadow: var(--box_shadow);
  border-radius: var(--border_radius) !important;
}

.mobile-only {
  display: none;
}

.ReactModal__Overlay {
  opacity: 9;
}

@media only screen and (max-width: 1200px) {
  .max-width {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 600px) {
  .homepage h1 {
    text-align: center;
    margin: 0 !important;
  }

  .homepage .practice-button {
    width: 100%;
  }

  .mobile-only {
    display: block;
  }

  .max-width {
    padding: 0 20px;
    width: auto;
  }

  .no-flex-mobile {
    display: block;
  }

  .no-max-width-mobile,
  .max-width.no-max-width-mobile {
    width: unset !important;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .mobile-max-width {
    width: 100%;
  }
}
